import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTwitter,
  faInstagram,
  faFacebook,
} from '@fortawesome/free-brands-svg-icons';

import {
  faInfo,
  faChartArea,
  faClock,
  faComment,
  faEye,
  faHourglassStart,
  faInfoCircle,
  faPlay,
  faSearch,
  faStar,
  faThumbsUp,
  faExclamationCircle,
  faUser,
  faFilter,
  faCalendar,
  faAddressCard,
  faLock,
  faEnvelopeOpenText,
  faEnvelope,
  faInbox,
  faExternalLinkAlt,
  faChevronCircleDown,
  faChevronDown,
  faPlayCircle,
  faTimes,
  faTicketAlt,
  faEdit,
  faTrash,
  faPhone,
  faCity,
  faGlobe,
  faMapMarked,
  faBirthdayCake,
  faSpinner,
  faGamepad,
  faPlus,
  faHistory,
  faUserPlus,
  faUserCircle,
  faArrowRight,
  faChevronLeft,
  faChevronRight,
  faMapPin,
  faCode,
  faChartPie,
  faStream,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faStream,
  faInfo,

  faExclamationCircle,
  faChartPie,
  faCode,
  faMapPin,
  faChevronLeft,
  faChevronRight,
  faArrowRight,
  faChartArea,
  faUserCircle,
  faUserPlus,
  faHistory,
  faPlus,
  faGamepad,
  faSearch,
  faUser,
  faThumbsUp,
  faPlay,
  faEye,
  faComment,
  faStar,
  faClock,
  faInfoCircle,
  faHourglassStart,
  faEye,
  faClock,
  faFilter,
  faCalendar,
  faAddressCard,
  faLock,
  faEnvelope,
  faEnvelopeOpenText,
  faUser,
  faInbox,
  faExternalLinkAlt,
  faSpinner,
  faInstagram,
  faTwitter,
  faTimes,
  faSearch,
  faUser,
  faThumbsUp,
  faPlay,
  faEye,
  faComment,
  faStar,
  faClock,
  faInfoCircle,
  faHourglassStart,
  faEye,
  faClock,
  faFilter,
  faCalendar,
  faAddressCard,
  faLock,
  faEnvelope,
  faEnvelopeOpenText,
  faUser,
  faInbox,
  faExternalLinkAlt,
  faPhone,
  faCity,
  faGlobe,
  faMapMarked,
  faBirthdayCake,
  faChevronCircleDown,
  faChevronDown,
  faPlayCircle,
  faTicketAlt,
  faEdit,
  faTrash,
  faFacebook
);
