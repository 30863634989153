import React from 'react';
import classes from './Cards.module.css';

import { VictoryPie, VictoryTheme } from 'victory';
import { useState } from 'react';
import { useEffect } from 'react';

export default function Cards({ item }) {
  const defaultGraphicData = [
    { y: 100, key: '' },
    { y: 100, key: '' },
  ]; // Data used to make the animate prop work

  const [graphicData, setGraphicData] = useState(defaultGraphicData);

  const [usedValueInPercent, setUsedValueInPercent] = useState(100);

  useEffect(() => {
    setGraphicData([
      {
        key: '',
        y: item?.valueUsedPercent * 1,
      },
      {
        key: '',
        y: 100 - item?.valueUsedPercent * 1,
        // 100-minus percentage used will gove this second value
      },
    ]);
    setUsedValueInPercent(item?.valueUsedPercent);
  }, []);

  return (
    <div className={classes.Cards}>
      <article className={`${classes.item}  `}>
        {/* <span className={`${classes.span} ${item?.color}`}>{item?.icon}</span> */}
        <div style={{ position: 'relative' }}>
          <VictoryPie
            padAngle={0}
            // used to hide labels
            labelComponent={<span />}
            innerRadius={70}
            width={200}
            height={200}
            animate={{
              duration: 10,
              easing: 'exp',
            }}
            data={graphicData}
            colorScale={[item?.color, '#EEEEEE']}
          />
          <p className={`${classes.pieChartItem} font-600`}>
            {usedValueInPercent} %
          </p>
        </div>
        <div className={classes.cardText}>
          <div className={classes.cardValue}>
            {item?.valueUsed} / {item?.value}
          </div>{' '}
          <div className={classes.cardValue1}>{item?.label2}</div>
        </div>

        {/* <p>{item?.label}</p> */}
        {/* <p className={classes.cardValue1}>{item?.valueUsedPercent} % used</p> */}
      </article>
    </div>
  );
}
