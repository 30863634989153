import React from "react";
import Button from "../../shared/Button/Button";

import classes from "./Organizations.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Organizations({
	organizationsList,
	deleteOrganizationHandler,
	originName,
}) {
	return (
		organizationsList && (
			<>
				<div className={`${classes.projects_details}  `}>
					<div className={classes.projects_info__order_number}>#</div>

					<div className={classes.project_info__created}> Name</div>

					<div className={classes.project_info__action}>Action</div>
				</div>
				{organizationsList?.length > 0 ? (
					<>
						{" "}
						{organizationsList.map((el, i) => (
							<div className={`${classes.projects_detail}`} key={el?.organizationId * 1 + i}>
								<div className={classes.projects_info__order_number}>{i + 1}</div>
								<div className={`${classes.project_info__created} ${classes.subOrgName}`}>
									{originName?.includes("my3.icareus") ? (
										el?.name
									) : (
										<a href={el.groupUrl} target="_blank" rel="noopener noreferrer">
											{" "}
											{el?.name}
										</a>
									)}
								</div>
								<div className={classes.project_info__action}>
									{/* <Button className='project-info__openbutton'>
        {/* <FontAwesomeIcon icon='edit' /> */}

									{/* <img src='https://my.icareus.com/icareus-suite-theme/images/common/edit.png' />
      </Button>   */}

									<Button
										className="project-info__openbutton"
										to={`/storage/${el?.organizationId}`}
									>
										<FontAwesomeIcon icon="info" color="#666" />
									</Button>

									{/* <Button
        // danger
        className='project-info__deletebutton'
        onClick={() => deleteOrganizationHandler(el)}
      >
        {/* <FontAwesomeIcon icon='trash' /> */}
									{/* <img src='https://my.icareus.com/icareus-suite-theme/images/common/delete.png' />
      </Button> */}
								</div>
							</div>
						))}
					</>
				) : (
					<div className="display-flex-center" style={{ paddingBottom: "50px" }}>
						Sub Accounts not added
					</div>
				)}
			</>
		)
	);
}
