import React, { useContext } from 'react';
import { registerUserWithRoles } from '../scripts/dataHandler';
import { useMyContext } from './StateHolder';

const MyContextFunctions = React.createContext();

const ContextFunctions = (props) => {
  // Bring stateholders from another context

  const registerProcess = async (
    inputs,
    role,
    token,
    organizationId,
    serverName
  ) => {
    try {
      const response = await registerUserWithRoles(
        organizationId,
        inputs,
        role,

        token,
        serverName
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MyContextFunctions.Provider
      value={{
        registerProcess,
      }}
    >
      {props.children}
    </MyContextFunctions.Provider>
  );
};

export const useMyContextFunctions = () => useContext(MyContextFunctions);

export default ContextFunctions;
