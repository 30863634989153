import React from 'react';
import classes from './Header.module.css';
import { useHistory } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import { FaChevronLeft, FaSearch } from 'react-icons/fa';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Header({
  showTitle,
  extraClassname,
  title,
  styles,
  admin,
  showSearchBox,
  searchFieldInput,
  setSearchFieldInput,
  searchFormHandler,
  showBackButton,
  color,
  hideSearchIcon,
  showButton,
  to,
  buttonLabel,
}) {
  // const { t } = useTranslation();

  const createOrgButton = {
    padding: '10px 20px',
  };
  return (
    <div
      style={{ color: color && color, ...styles }}
      className={`${classes.HeaderTitle}  ${
        admin && classes.HeaderTitleAdmin
      }    `}
    >
      <div>{showTitle !== false && title}</div>
      {showSearchBox && (
        <div className={classes.searchContainer_primary}>
          <div className={classes.searchContainer_primary_back}>
            <form
              id={classes.searchForm}
              onSubmit={(e) => searchFormHandler(e)}
            >
              <div className={classes.searchRow}>
                <div className={`${classes.searchColumn} font-300`}>
                  <input
                    className={`${classes.searchField} font-300 `}
                    type='text'
                    value={searchFieldInput}
                    onChange={(e) => setSearchFieldInput(e.target.value)}
                  />
                  {!hideSearchIcon && (
                    <FaSearch
                      className={`${classes.FaIcon} ${classes.FaIconSearch}`}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {showButton && (
        <div className={classes.searchContainer_primary}>
          <div className={classes.searchContainer_primary_back}>
            <Button
              inverse
              className='project-info__deletebutton'
              to={to}
              style={createOrgButton}
            >
              {buttonLabel}
              <FontAwesomeIcon icon='plus' style={{ marginLeft: '10px' }} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
