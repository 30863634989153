import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useMyContext } from '../contexts/StateHolder';
import { getChannels, getOrganizationStorage } from '../scripts/dataHandler';
import { createOrganizationStorageToken } from '../scripts/util';
import Cards from '../shared/Cards/Cards';
import classes from '../shared/Cards/Cards.module.css';
import { GoRepo, GoGist } from 'react-icons/go';
import { GrStorage, GrChannel } from 'react-icons/gr';
import { MdSdStorage } from 'react-icons/md';
import { FiUsers, FiUserPlus } from 'react-icons/fi';
import Header from '../shared/Header/Header';

export default function Storage() {
  const { orgId } = useParams();

  const {
    organizationsList,
    setOrganizationsList,
    organizationId,
    key,
    serverName,
  } = useMyContext();

  const [storageItem, setStorageItem] = useState(null);

  useEffect(() => {
    if (orgId && organizationId && key) {
      if (organizationsList?.length > 0) {
        const runFunction = async () => {
          let secret = organizationsList.find(
            (el) => el?.organizationId * 1 === orgId * 1
          )?.secret;

          const token = createOrganizationStorageToken(orgId, secret);

          const res = await getOrganizationStorage(orgId, token, serverName);

          const roundValue = (num) => {
            return num.toFixed(0);
          };

          const items = [
            {
              id: 3,
              icon: <GrChannel className={classes.icon} />,
              label: 'Channels Quota',
              label2: 'Channels Used',
              value: res?.data?.data?.channelQuota,
              valuePieChart: res?.data?.data?.channelQuota,
              valueUsed: res?.data?.data?.channelCount,

              valueUsedPercent: roundValue(
                ((res?.data?.data?.channelCount
                  ? res?.data?.data?.channelCount
                  : 0) /
                  (res?.data?.data?.channelQuota
                    ? res?.data?.data?.channelQuota
                    : 200)) *
                  100
              ),

              color: 'purple',
            },

            {
              id: 2,
              icon: <MdSdStorage className={classes.icon} />,
              label: 'Storage Quota',
              label2: 'Storage Used',

              value: res?.data?.data?.storageQuota
                ? `${res?.data?.data?.storageQuota} GB `
                : '200 GB',
              valuePieChart: res?.data?.data?.storageQuota
                ? `${res?.data?.data?.storageQuota} `
                : 0,
              valueUsed: res?.data?.data?.storageUsed
                ? `${roundValue(res?.data?.data?.storageUsed)} `
                : '0 ',
              valueUsedPercent: roundValue(
                ((res?.data?.data?.storageUsed
                  ? res?.data?.data?.storageUsed
                  : 0) /
                  (res?.data?.data?.storageQuota
                    ? res?.data?.data?.storageQuota
                    : 200)) *
                  100
              ),

              color: 'green',
            },
          ];

          setStorageItem(items);
        };
        runFunction();
      } else if (!organizationsList) {
        const runFunction2 = async () => {
          const res = await getChannels(organizationId, key, serverName);
          setOrganizationsList([...res?.data?.organization?.subOrganizations]);
        };

        runFunction2();
      }
    }
  }, [orgId, JSON.stringify(organizationsList), organizationId, key]);

  return (
    storageItem && (
      <div className='homePageMainContainer' style={{ padding: '20px 0' }}>
        <Header
          title={
            organizationsList.find((el) => el?.organizationId * 1 === orgId * 1)
              ?.name
          }
          styles={{
            color: 'black',
            // justifyContent: 'center',
          }}
          color='#161b55'
          extraClassname={true}
        />
        <div className={classes.storageItem}>
          {storageItem.map((el) => (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
              key={el?.id}
            >
              {el?.label}
              <Cards item={el} key={el?.id} />
            </div>
          ))}
        </div>
      </div>
    )
  );
}
