import axios from 'axios';
import { useMyContext } from '../contexts/StateHolder';
import { countries } from './countries';
import { createSubOrgToken } from './util';

let API_Server_BETA = 'https://suiterc.icareus.com';
let API_Server_PROD = 'https://my.icareus.com';

export const getChannels = async (organizationId, secret, serverName) => {
  try {
    const res = await axios.get(
      `${
        serverName.includes('suiterc') ? API_Server_BETA : API_Server_PROD
      }/api/organization?action=getOrganization&version=04&organizationId=${organizationId}&includeSubOrganizations=true&token=${secret}`
    );
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getOrganizationStorage = async (
  organizationId,
  token,
  serverName
) => {
  try {
    const res = await axios.get(
      `${
        serverName.includes('suiterc') ? API_Server_BETA : API_Server_PROD
      }/delegate/account?action=getOrganizationStats&organizationId=${organizationId}&token=${token}`
    );
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const createNewClub = async (
  organizationId,
  name,
  countryId,
  regionId,
  postalCode,
  cityName,

  phoneNumber,
  emailAddress,

  key,
  edition,
  templateId,
  serverName,

  accountType = 'full-organization'
) => {
  try {
    let token = createSubOrgToken(
      organizationId,
      accountType,
      name,
      countryId,
      regionId,
      postalCode,
      cityName,
      phoneNumber,
      emailAddress,
      key
    );

    console.log(
      'organizationId',
      name,
      countryId,
      regionId,
      postalCode,
      cityName,

      phoneNumber,
      emailAddress,

      key,

      ' organizationId',
      name,
      countryId,
      regionId,
      postalCode,
      cityName,

      phoneNumber,
      emailAddress,

      key
    );

    console.log('token', token);
    // const url = `https://my.icareus.com/delegate/account?action=addOrganization&organizationId=${organizationId}&accountType=${accountType}&name=${name}&countryId=${countryId}&regionId=${regionId}&postalCode=${postalCode}&cityName=${cityName}&phoneNumber=${phoneNumber}&emailAddress=${emailAddress}&token=${token}&nodeonClubId=${nodeonClubId}&edition=ismaccount&templateId=1`;

    const url = `${
      serverName.includes('suiterc') ? API_Server_BETA : API_Server_PROD
    }/delegate/account?action=addOrganization&organizationId=${organizationId}&accountType=${accountType}&name=${name}&countryId=${countryId}&regionId=${regionId}&postalCode=${postalCode}&cityName=${cityName}&phoneNumber=${phoneNumber}&emailAddress=${emailAddress}&token=${token}&edition=${edition}&templateId=${templateId}`;

    console.log('urldata', url);
    // return;
    const res = await axios.get(url);

    console.log('rescreatenew_organization', res);
    return res?.data;
  } catch (error) {
    console.log(error);
    console.log(error?.message);
    console.log(error?.stack);
  }
};
export const getAllSuborganization = async (
  organizationId,
  key,
  serverName
) => {
  try {
    const res = await getChannels(organizationId, key, serverName);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const registerUserWithRoles = async (
  organizationId,
  inputs,
  role,
  token,
  serverName
) => {
  try {
    console.log(organizationId, inputs, role, token);
    let p1 = encodeURIComponent(inputs?.PASSWORD?.value);

    let url = `${
      serverName.includes('suiterc') ? API_Server_BETA : API_Server_PROD
    }/api/register?action=addSubscriber`;

    if (role?.includes('admin')) {
      // we also need to pass token when creating admin
      url = `${url}&token=${token}`;
    }
    const registerResponse = await axios.get(url, {
      params: {
        organizationId,
        emailAddress: inputs?.EMAIL?.value,
        userPassword: p1,
        ...(inputs?.PHONE?.value ? { phoneNumber: inputs?.PHONE?.value } : {}),
        ...(inputs?.CITY?.value ? { cityName: inputs?.CITY?.value } : {}),
        ...(inputs?.DATEOFBIRTH?.value
          ? { dateOfBirth: inputs?.DATEOFBIRTH?.value }
          : {}),
        ...(inputs?.COUNTRY?.value
          ? {
              countryId: countries.find(
                (country) => country.name === inputs?.COUNTRY?.value
              )?.id,
            }
          : {}),
        ...(inputs?.FIRSTNAME?.value
          ? { firstName: inputs?.FIRSTNAME?.value }
          : {}),
        ...(inputs?.LASTNAME?.value
          ? { lastName: inputs?.LASTNAME?.value }
          : {}),
        ...(inputs?.ADDRESS?.value ? { address1: inputs?.ADDRESS?.value } : {}),
        // if no role means register as a subscriber
        ...(role ? { role: role } : { role: 'ismsubscriber' }),
      },
    });

    console.log('Register: ', registerResponse);
    return registerResponse;
  } catch (err) {
    console.log(err);
  }
};
