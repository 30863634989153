const crypto = require('crypto');

const getCurrentTime = () => {
  return Math.floor(new Date().getTime() / 1000).toString(16);
};
export const createOrganizationStorageToken = (organizationId, key) => {
  const currentTimeStr = getCurrentTime();

  let arg = '' + organizationId + ':' + currentTimeStr + ':' + key;

  const signature = crypto.createHash('md5').update(arg).digest('hex');
  let f = '01' + currentTimeStr + signature;
  return f;
};

export const createAdminUserToken = (organizationId, key) => {
  console.log('first', organizationId, key);
  let currentTime = 0x12345678;
  let currentTimeStr = currentTime.toString(16);
  while (currentTimeStr.length < 8) {
    currentTimeStr = '0' + currentTimeStr;
  }
  let arg = '' + organizationId + ':' + currentTimeStr + ':' + key;
  let hash = crypto.createHash('md5').update(arg).digest('hex');
  let token = '01' + currentTimeStr + hash;
  return token;
};
export const createSubOrgToken = (
  organizationId,
  accountType,
  name,
  countryId,
  regionId,
  postalCode,
  cityName,

  phoneNumber,
  emailAddress,

  key
) => {
  const currentTimeStr = getCurrentTime();

  let arg =
    '' +
    organizationId +
    ':' +
    accountType +
    ':' +
    name +
    ':' +
    countryId +
    ':' +
    regionId +
    ':' +
    postalCode +
    ':' +
    cityName +
    ':' +
    phoneNumber +
    ':' +
    emailAddress +
    ':' +
    currentTimeStr +
    ':' +
    key;

  const signature = crypto.createHash('md5').update(arg).digest('hex');
  let f = '02' + currentTimeStr + signature;
  return f;
};
