import { useState } from "react";
import { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { TailSpin } from "react-loader-spinner";

import AddNewSuborganization from "./components/Organizations/AddNewSuborganization/AddNewSuborganization";
import configs from "./configs/configs";
import { useMyContext } from "./contexts/StateHolder";
import Home from "./pages/Home";
import Storage from "./pages/Storage";
import "./shared/FontAwesomeIcon.js";

// toastify
import "react-toastify/dist/ReactToastify.css";

function App() {
	const {
		setOrganizationId,
		setKey,

		setTemplateId,

		setUserId,

		setEdition,

		setServerName,
		organizationId,

		setOriginName,
	} = useMyContext();

	const [cookies, setCookie] = useCookies(["oif"]);

	//Function that get params from URL
	function getParam() {
		return new URLSearchParams(window.location.search.toString());
	}

	const [authLoaded, setAuthLoaded] = useState(false);

	// useEffect(() => {
	//   // setOrganizationId(172610522);
	//   // setKey('JvZ1xC6N2r');
	//   // setOrganizationId(4692809);
	//   // setKey('SgtKzUCp29');
	//   // "organizationId": 4692809,
	//   // "key": "SgtKzUCp29",
	//   setOrganizationId(181282321);
	//   setKey('XqjVf6W8yu');
	// }, []);

	// useEffect(() => {
	//   if (organizationId) {
	//     setAuthLoaded(true);
	//   }
	// }, [organizationId]);

	useEffect(() => {
		let orgId = getParam().get("organizationId");

		if (orgId) {
			// this means there is query params already
			let sec = getParam().get("secret");

			let user_id = getParam().get("secret");
			let template_id = getParam().get("templateId");

			let edition_id = getParam().get("edition");
			let server_name = getParam().get("serverName");

			let origin_name = getParam()?.get("origin");

			setServerName(server_name);
			setTemplateId(template_id);
			setUserId(user_id);
			setEdition(edition_id);
			setOrganizationId(orgId);
			setKey(sec);
			setOriginName(origin_name);

			setCookie(
				"oif",
				{
					secret: sec,
					userId: user_id,
					templateId: template_id,
					serverName: server_name,
					organizationId: orgId,
					edition: edition_id,
					origin: origin_name,
				},
				{
					path: "/",
				}
			);
			setAuthLoaded(true);
		} else if (cookies?.oif) {
			setServerName(cookies?.oif?.serverName);
			setTemplateId(cookies?.oif?.templateId);
			setUserId(cookies?.oif?.userId);
			setEdition(cookies?.oif?.edition);
			setOrganizationId(cookies?.oif?.organizationId);
			setKey(cookies?.oif?.secret);
			setOriginName(cookies?.oif?.origin || getParam()?.get("origin"));
			setAuthLoaded(true);
		} else {
			setAuthLoaded(true);
		}
	}, []);

	return authLoaded ? (
		organizationId ? (
			<div className="main-container">
				<Switch>
					<Route path={configs.routes.home} exact>
						<Home />
					</Route>
					<Route path={configs.routes.subOrganization} exact>
						<Storage />
					</Route>
					<Route path={configs.routes.createOrganization} exact>
						<AddNewSuborganization />
					</Route>
				</Switch>
			</div>
		) : (
			<div className="display-flex-center">No access granted</div>
		)
	) : (
		<div className="display-flex-center">
			{" "}
			<TailSpin type="TailSpin" color="#161eaf" />
		</div>
	);
}

export default App;
