import React, { useCallback, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";

import Organizations from "../components/Organizations/Organizations";
import { useMyContext } from "../contexts/StateHolder";
import { getChannels } from "../scripts/dataHandler";
import Header from "../shared/Header/Header";
import configs from "../configs/configs";

export default function Home() {
	const {
		organizationId,
		key,
		organizationsList,
		setOrganizationsList,
		serverName,
		accountCreated,
		setAccountCreated,
		originName,
	} = useMyContext();

	const [loading, setLoading] = useState(false);

	const runFunction = useCallback(async () => {
		try {
			setLoading(true);
			setAccountCreated(false);
			const res = await getChannels(organizationId, key, serverName);
			console.log("res", res);

			if (res?.data?.status === "ok") {
				setOrganizationsList([...res?.data?.organization?.subOrganizations]);
			}
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		key && organizationId && !organizationsList && runFunction();
	}, [key, organizationId]);

	useEffect(() => {
		if (accountCreated) {
			runFunction();
		}
	}, [accountCreated]);

	const deleteOrganizationHandler = (el) => {
		console.log(el);
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 100);
	};

	return (
		<div>
			{loading && (
				<div className="display-flex-center">
					<TailSpin type="TailSpin" color="#161eaf" />
				</div>
			)}

			{!loading && (
				<div className="homePageMainContainer">
					<div style={{ padding: "20px 0" }}>
						{" "}
						<Header
							title={"Sub Accounts"}
							extraClassname
							styles={{ color: "black" }}
							showButton={true}
							to={configs.routes.createOrganization}
							buttonLabel={"Create New Account"}
						/>
					</div>

					<div style={{ background: "white" }}>
						<Organizations
							organizationsList={organizationsList}
							deleteOrganizationHandler={deleteOrganizationHandler}
							originName={originName}
						/>
					</div>
				</div>
			)}
		</div>
	);
}
