import axios from "axios";

const crypto = require("crypto");

let API_Server_BETA = "https://suiterc.icareus.com";
let API_Server_PROD = "https://my.icareus.com";

const getCurrentTime = () => {
	return Math.floor(new Date().getTime() / 1000).toString(16);
};

const assignUserRoleToken = (params) => {
	const currentTime = getCurrentTime();
	const { userId, organizationId, secret } = params;
	const signature = crypto
		.createHash("md5")
		.update(`${organizationId}:${userId}:${currentTime}:${secret}`)
		.digest("hex");

	return "01" + currentTime + signature;
};

const assignToken = (params) => {
	const currentTime = getCurrentTime();
	const { userId, role = "admin", organizationId, secret } = params;
	const signature = crypto
		.createHash("md5")
		.update(`${organizationId}:${userId}:${role}:${currentTime}:${secret}`)
		.digest("hex");

	return "01" + currentTime + signature;
};

export const assignUserToOrganization = async (paramsObject) => {
	const { organizationId, secret, userId, serverName } = paramsObject;
	const token = assignUserRoleToken({ userId, organizationId, secret });

	let URL = serverName.includes("suiterc") ? API_Server_BETA : API_Server_PROD;

	let params = {
		organizationId,
		userId,

		token,
		_: Date.now(),
	};

	return await axios.get(`${URL}/delegate/account?action=assignUser`, {
		params,
	});
};

export const assignUserRole = async (paramsObject) => {
	const { organizationId, secret, userId, role, serverName } = paramsObject;
	let token = assignToken({ userId, role, organizationId, secret });

	let URL = serverName.includes("suiterc") ? API_Server_BETA : API_Server_PROD;

	let params = {
		organizationId,
		userId,

		token,
		role,
		_: Date.now(),
	};

	return await axios.get(`${URL}/delegate/account?action=assignUserRole`, {
		params,
	});
};

const createToken = (params) => {
	const currentTime = getCurrentTime();
	const {
		firstName,
		lastName,
		countryId = 1,
		regionId = 0,
		postalCode,
		cityName,
		phoneNumber,
		email,
		organizationId,
		secret,
	} = params;

	const signature = crypto
		.createHash("md5")
		.update(
			`${organizationId}:${firstName}:${lastName}:${countryId}:${regionId}:${postalCode}:${cityName}:${phoneNumber}:${email}:${currentTime}:${secret}`
		)
		.digest("hex");

	return "01" + currentTime + signature;
};

export const createUser = (paramsObject) => {
	const {
		action,
		organizationId,
		firstName = "User",
		lastName = "User",
		email,
		cityName,
		phoneNumber,
		postalCode,
		secret,
		password,
		serverName,
	} = paramsObject;
	const token = createToken({
		firstName,
		lastName,
		email,
		cityName,
		phoneNumber,
		postalCode,
		organizationId,
		secret,
	});

	let params = {
		organizationId,
		firstName,
		lastName,
		countryId: 1,
		regionId: 0,
		postalCode,
		cityName,
		phoneNumber,
		emailAddress: email,
		token,
		password,
		_: Date.now(),
	};

	let URL = serverName.includes("suiterc") ? API_Server_BETA : API_Server_PROD;

	return axios.get(`${URL}/delegate/account?action=${action}`, { params });
};
