import React from "react";
import { Link } from "react-router-dom";

import "./Button.css";

const Button = (props) => {
	console.log("props", props);
	if (props.href) {
		return (
			<a
				className={`button button--${props.size || "default"} ${
					props.inverse && "button--inverse"
				} ${props.danger && "button--danger"}
        ${props.formButton && "button--form"}
        smallcasebold
        
        `}
				href={props.href}
				style={props.style}
			>
				{props.children}
			</a>
		);
	}
	if (props.to) {
		return (
			<Link
				aria-disabled={props?.disabled ? true : false}
				to={props.to}
				exact={props.exact}
				className={`button button--${props.size || "default"} ${
					props.inverse && "button--inverse"
				} ${props.danger && "button--danger"} ${props.formButton && "button--form"} smallcasebold`}
				style={props.style}
			>
				{props.children}
			</Link>
		);
	}
	return (
		<button
			className={`button button--${props.size || "default"} ${props.inverse && "button--inverse"} ${
				props.danger && "button--danger"
			}
      ${props.formButton && "button--form"}
      smallcasebold
      
      `}
			type={props.type}
			onClick={props.onClick}
			disabled={props.disabled}
			style={props.style}
		>
			{props.children}
		</button>
	);
};

export default Button;
