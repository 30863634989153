import React from "react";
import ReactDOM from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter as Router } from "react-router-dom";

import StateHolder from "./contexts/StateHolder";
import ContextFunctions from "./contexts/ContextFunctions";

import "./index.css";
import App from "./App";
import "./i18n/config";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.NODE_ENV === "production") {
	console.log = () => {};
	console.error = () => {};
	console.debug = () => {};
}
root.render(
	// <React.StrictMode>
	<Router>
		<CookiesProvider>
			<StateHolder>
				<ContextFunctions>
					<App />
				</ContextFunctions>
			</StateHolder>
		</CookiesProvider>
	</Router>
	// </React.StrictMode>
);
