import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";

import Header from "../../../shared/Header/Header";
import { useMyContext } from "../../../contexts/StateHolder";
import { Input1 } from "../../../shared/Input1/Input1";

import {
	VALIDATOR_REQUIRE,
	VALIDATOR_EMAIL,
	VALIDATOR_MINLENGTH,
	VALIDATOR_PASSWORDMATCH,
} from "../../../shared/Validation/Validator";

import * as classes from "../../../shared/Input1/Input1.module.css";
import Button from "../../../shared/Button/Button";

import { createNewClub } from "../../../scripts/dataHandler";

import { useForm } from "../../../shared/Hooks/form-hook";
import {
	assignUserRole,
	assignUserToOrganization,
	createUser,
} from "../../../scripts/user-handler";

export default function AddNewSuborganization() {
	const { t } = useTranslation();

	const { organizationId, key, edition, templateId, serverName, setAccountCreated } =
		useMyContext();

	const [loading, setLoading] = useState(false);
	const [accountLoading, setAccountLoading] = useState(false);

	const history = useHistory();

	let [state, InputHandler] = useForm(
		{
			CLUB_NAME: {
				CLUB_NAME: "",
				isValid: false,
			},

			POSTAL_CODE: {
				POSTAL_CODE: "",
				isValid: false,
			},
			CITY_TOWN: {
				CITY_TOWN: "",
				isValid: false,
			},
			PHONE_NUMBER: {
				PHONE_NUMBER: "",
				isValid: false,
			},
			CLUB_EMAIL: {
				CLUB_EMAIL: "",
				isValid: false,
			},

			EMAIL: {
				value: "",
				isValid: false,
			},

			PASSWORD: {
				value: "",
				isValid: false,
			},
			PASSWORDCONFIRM: {
				value: "",
				isValid: false,
			},
		},
		false
		// the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
	);

	const formSubmitHandler = async (e) => {
		e.preventDefault();
		console.log(state);
		setAccountLoading(true);

		const res = await createNewClub(
			organizationId,
			state.inputs.CLUB_NAME.value,
			88,
			88,
			state.inputs?.POSTAL_CODE?.value,
			state.inputs?.CITY_TOWN?.value,
			state.inputs?.PHONE_NUMBER?.value,
			state.inputs?.CLUB_EMAIL?.value,
			key,
			edition,
			templateId,
			serverName
		);

		// let res = {
		// 	status: "ok",
		// };

		if (res?.status === "ok") {
			// we create admin if account creation successfully
			let secret = res?.key;
			let orgId = res?.organizationId;

			const createUserParams = {
				action: "addUser",
				organizationId: orgId,
				email: state.inputs?.EMAIL?.value,
				cityName: state.inputs?.CITY_TOWN?.value,
				phoneNumber: state.inputs?.PHONE_NUMBER?.value,
				postalCode: state.inputs?.POSTAL_CODE?.value,
				secret: secret,
				password: state.inputs?.PASSWORD?.value,
				serverName,
			};

			const createdUser = await createUser(createUserParams);

			const userId = createdUser?.data?.userId;

			if (!userId) {
				return toast.error("Something went wrong.");
			}

			const assignUserRoleParams = {
				organizationId: orgId,

				secret: secret,
				userId,
				role: "admin",
				serverName,
			};

			console.log("createdUser", createdUser);

			await Promise.all([
				assignUserRole(assignUserRoleParams),
				assignUserToOrganization(assignUserRoleParams),
			]);

			toast.success("Account Created");
			setAccountLoading(false);
			setAccountCreated(true);
			setTimeout(() => {
				history.push("/");
			}, 2000);
		} else {
			setAccountLoading(false);

			return toast.error("Account Creation Failed");
		}
	};

	return (
		<div className="loginViewContainer">
			<ToastContainer />
			{accountLoading && (
				<div className={accountLoading ? "copy-container active regular" : ""}>
					<div className={accountLoading ? "copy-popup active regular" : ""}>
						<div
							className="font-600"
							style={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<div className="display-flex-center">
								{" "}
								<TailSpin type="TailSpin" color="#161eaf" />
							</div>
							<div style={{ display: "flex", margin: "10px 0 20px 0" }}>
								{t("addNewSubOrganization.creatingClub")}
							</div>
						</div>
					</div>
				</div>
			)}

			<div
				className={`${classes.formPrimary} homePageMainContainer `}
				style={{ paddingTop: "20px" }}
			>
				<div className={classes.formSecondary}>
					{!loading ? (
						<form className={classes.formBlogAdmin} onSubmit={formSubmitHandler}>
							<Header
								// title={t('addNewSubOrganization.title')}
								title="Create New Account"
								extraClassname
								style={{ justifyContent: "center", color: "black" }}
								color="black"
							/>
							<Input1
								id="CLUB_NAME"
								type="text"
								element="input"
								validators={[VALIDATOR_REQUIRE()]}
								label={t("addNewSubOrganization.clubName")}
								errorText={t("addNewSubOrganization.invalidClubName")}
								placeholder={t("addNewSubOrganization.clubName")}
								onInput={InputHandler}
							/>
							{/* <Input1
                id='COUNTRY'
                type='text'
                element='select_dropdown'
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t('userDetails.askForCountry')}
                placeholder={t('userDetails.inputPlaceholderCountry')}
                label={t('userDetails.inputPlaceholderCountry')}
                onInput={InputHandler}
                selectData={countryList}
                colorStyles={colorStyles}
              /> */}
							{/* <Input1
                id='STREET_ADDRESS'
                label={t('addNewSubOrganization.streetAddress')}
                errorText={t('addNewSubOrganization.invalidstreetAddress')}
                placeholder={t('signUpForm.country')}
                type='text'
                element='input'
                validators={[VALIDATOR_REQUIRE()]}
                onInput={InputHandler}
              />{' '} */}
							<Input1
								id="POSTAL_CODE"
								label={t("addNewSubOrganization.postalCode")}
								errorText={t("addNewSubOrganization.invalidpostalCode")}
								type="text"
								element="input"
								validators={[VALIDATOR_REQUIRE()]}
								onInput={InputHandler}
							/>{" "}
							<Input1
								id="CITY_TOWN"
								label={t("addNewSubOrganization.cityTown")}
								errorText={t("addNewSubOrganization.invalidcityTown")}
								type="text"
								element="input"
								validators={[VALIDATOR_REQUIRE()]}
								onInput={InputHandler}

								//
							/>{" "}
							<Input1
								id="PHONE_NUMBER"
								label={t("addNewSubOrganization.phoneNumber")}
								errorText={t("addNewSubOrganization.invalidphoneNumber")}
								type="text"
								element="input"
								validators={[VALIDATOR_REQUIRE()]}
								onInput={InputHandler}
								//
							/>{" "}
							<Input1
								id="CLUB_EMAIL"
								label={t("addNewSubOrganization.email")}
								errorText={t("addNewSubOrganization.invalidemail")}
								placeholder={t("loginForm.askForPassword")}
								type="text"
								element="input"
								validators={[VALIDATOR_EMAIL()]}
								onInput={InputHandler}
								// iconName='user'
							/>{" "}
							<Header
								title={t("addNewSubOrganization.contactPerInfo")}
								extraClassname
								style={{ justifyContent: "center" }}
								color="black"
							/>
							<Input1
								id="EMAIL"
								placeholder={t("loginForm.askForPassword")}
								type="text"
								element="input"
								validators={[VALIDATOR_EMAIL()]}
								label={t("addNewSubOrganization.emailPerson")}
								errorText={t("addNewSubOrganization.invalidemail")}
								onInput={InputHandler}
								// iconName='user'
							/>{" "}
							<p
								className="font-100 smallcasebold"
								style={{
									textAlign: "center",
									marginTop: "-10px",
									color: "black",
								}}
							>
								{t("addNewSubOrganization.accountCreationWithSameEmail")}
							</p>
							<Input1
								id="PASSWORD"
								label={t("signUpForm.password")}
								placeholder={t("signUpForm.askForPassword")}
								type="Password"
								element="input"
								validators={[VALIDATOR_MINLENGTH(5)]}
								errorText={t("signUpForm.errorPasswordNotLongEnough", {
									count: "5",
								})}
								onInput={InputHandler}
								// iconName='lock'
							/>
							<Input1
								id="PASSWORDCONFIRM"
								label={t("signUpForm.passwordConfirm")}
								placeholder={t("signUpForm.askForConfirmPassword")}
								type="Password"
								element="input"
								validators={[
									VALIDATOR_PASSWORDMATCH(
										state.inputs?.PASSWORD.value,
										state.inputs?.PASSWORDCONFIRM.value
									),
								]}
								errorText={t("signUpForm.errorPasswordsDontMatch")}
								onInput={InputHandler}
								// iconName='lock'
							/>
							<Button
								className={classes.loginFormButton}
								disabled={!state.isValid || accountLoading}
								formButton
								inverse
							>
								{/* {t('loginForm.loginButton')} */}
								{accountLoading
									? t("addNewSubOrganization.creating")
									: t("addNewSubOrganization.create")}
							</Button>
						</form>
					) : (
						<div className="display-flex-center">
							{" "}
							<TailSpin type="TailSpin" color="#161eaf" />{" "}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
