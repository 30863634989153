import React, { useState, useContext } from "react";

const MyContext = React.createContext();

const StateHolder = ({ children }) => {
	const [organizationId, setOrganizationId] = useState(null);

	const [key, setKey] = useState(null);

	const [organizationsList, setOrganizationsList] = useState(null);

	const [userId, setUserId] = useState(null);
	const [templateId, setTemplateId] = useState(null);

	const [edition, setEdition] = useState(null);
	const [serverName, setServerName] = useState(null);

	const [originName, setOriginName] = useState(null);

	const [accountCreated, setAccountCreated] = useState(false);

	return (
		<MyContext.Provider
			value={{
				organizationId,
				setOrganizationId,

				key,
				setKey,

				organizationsList,
				setOrganizationsList,

				templateId,
				setTemplateId,

				userId,
				setUserId,

				edition,
				setEdition,

				serverName,
				setServerName,

				accountCreated,
				setAccountCreated,

				originName,
				setOriginName,
			}}
		>
			{children}
		</MyContext.Provider>
	);
};

export const useMyContext = () => useContext(MyContext);

export default StateHolder;
